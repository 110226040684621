import React from "react";

export const VALIDATION_FUNCTIONS = {
  textRequired:
    () =>
    (val = "") => {
      if (val && val.length > 0) {
        return "";
      }
      return "This field is required";
    },
  email:
    () =>
    (val = "") => {
      const emailTest = new RegExp(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      );
      if (emailTest.test(val)) {
        return "";
      }
      return "Please enter a valid email";
    },
  minLength:
    (minLength) =>
    (val = "") => {
      if (val.length >= minLength) {
        return "";
      }
      return `Must be at least ${minLength} characters`;
    },
  maxLength:
    (maxLength) =>
    (val = "") => {
      if (val.length <= maxLength) {
        return "";
      }
      return `Must be less than ${maxLength} characters`;
    },
};

export const validateField = ({ validations, value }) => {
  const fieldErrors = [];
  for (let i = 0; i < validations.length; i++) {
    const validate = VALIDATION_FUNCTIONS[validations[i].name](
      validations[i].options
    );
    const error = validate(value);
    if (error) {
      fieldErrors.push(error);
    }
  }
  return fieldErrors;
};

export const fields = [
  {
    id: "name",
    type: "text",
    validations: [{ name: "textRequired" }, { name: "minLength", options: 2 }],
  },
  {
    id: "email",
    type: "email",
    validations: [{ name: "email" }, { name: "textRequired" }],
  },
  {
    id: "organization",
    type: "text",
    validations: [],
  },
  {
    id: "services",
    type: "text",
    validations: [],
  },
  {
    id: "message",
    type: "textarea",
    validations: [
      { name: "textRequired" },
      { name: "minLength", options: 10 },
      { name: "maxLength", options: 1500 },
    ],
  },
];

export const useFieldErrors = (initialFields, initialValues) => {
  const [errors, setErrors] = React.useState({});
  const [touched, setTouched] = React.useState({});

  const setFieldErrors = (field, value, isTouched) => {
    setErrors((prevErrors) => {
      const fieldValidations = field.validations || [];
      const fieldErrors = validateField({
        validations: fieldValidations,
        value,
      });
      return {
        ...prevErrors,
        [field.id]: fieldErrors,
      };
    });

    if (isTouched) {
      setTouched((prevTouched) => ({
        ...prevTouched,
        [field.id]: true,
      }));
    }
  };

  React.useEffect(() => {
    if (Array.isArray(initialFields)) {
      initialFields.forEach((field) =>
        setFieldErrors(field, initialValues[field.id] || "", false)
      );
    }
  }, [initialValues]);

  return [errors, setFieldErrors, touched];
};
