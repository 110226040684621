import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { StaticImage } from "gatsby-plugin-image";
import "./success-page.scss";
import CTA from "../cta";
import { useCheckScreenSize } from "../../lib/checkScreenSize";

const SuccessPage = ({
  text,
  returnButton = false,
  isSubmitted = false,
  successPage = false,
}) => {
  const loadingContainerRef = useRef(null);
  const contentRef = useRef(null);
  const isMobile = useCheckScreenSize(800);

  gsap.set(loadingContainerRef.current, { y: "140%" });

  useEffect(() => {
    const tl = gsap.timeline();

    if (isSubmitted === true) {
      tl.to(loadingContainerRef.current, {
        y: "0",
        duration: 0.3,
        delay: 0,
        ease: "power4.out",
      });
    }
  }, [isSubmitted]);

  return (
    <div className="success-page" ref={loadingContainerRef}>
      <div className="success-page__image">
        {!isMobile && (
          <StaticImage
            src="../../images/entry-page/entryDesktop.jpeg"
            alt="Background Image"
            layout="fullWidth"
            quality={100}
            formats={["AUTO", "WEBP", "AVIF"]}
          />
        )}
        {isMobile && (
          <StaticImage
            src="../../images/entry-page/entryMobile.jpeg"
            alt="Background Image"
            layout="fullWidth"
            quality={100}
            formats={["AUTO", "WEBP", "AVIF"]}
          />
        )}
      </div>
      <div ref={contentRef} style={{ zIndex: 2 }} className="success-page__content">
        <div className="success-page__content__inner">
          <h1>{text}</h1>
          <div className="success-page__content__text">
            <p>Thanks for getting in touch!</p>
            <p>I'll reach back out to you as soon as I can! {":)"}</p>
          </div>
          {returnButton && <CTA to="/">Return home</CTA>}
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
