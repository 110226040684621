import React from "react";
import ContactForm from "../components/form/contact";
import EntryPage from "../components/EntryPage";
import "../styles/pages/contact.scss";

const Contact = () => {
  return (
    <>
      {/* <EntryPage text="get in touch!" /> */}
      <div className="db-contact">
        <div className="db-contact__inner">
          <div className="db-contact__header">
            <h1>Let's build together!</h1>
          </div>
          <div className="db-contact__form-info">
            <ContactForm />
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
